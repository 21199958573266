/*
.main-content > * > :first-child {
    height: 33vh;
}
*/

.main-content{
    display: flex;
    flex-direction: row;
}

.upline {
    justify-content: space-between;
    margin: -.7em 0 -1em;
}
.upline > * {
    max-width: 33%;
}

tr.active > td,
tr.details > td {
    background-color: rgba(45,114,210,.1) !important;
}

.main-content > .categories-list, .main-content > .keywords-list {
    flex-shrink: 1;
    flex-basis: 21%;
}
.main-content > .accs-list {
    flex-shrink: 0;
    /* width: 70vw; m*/
    width: 100%;
}

.accs-keywords > * {
    margin: .1em .3em;
}
.accs-keywords > * * {
    white-space: break-spaces !important;
}

.accs-description{
    width: 40%;
}
.accs-code{
    width: 20%;
}
.accs-title{
    width: 20%;
}
.accs-author{
    width: 15%;
}
.accs-actions{
    width: 5%;
}
td.accs-actions > * {
    opacity: 0;
    transition: opacity .7s;
}
:hover > td.accs-actions > * {
    opacity: 1;
}

.accs-code > [role="button"]{
    transition: opacity .7s;
    opacity: 0;
}

tr:hover .accs-code > [role="button"]{
    opacity: 1;
}

.accs-list > table > thead {
    position: sticky;
    top: 50px;
    background: white;
    z-index: 9;
}

.period-selector > * { display: flex }
