
#app .main {
  margin:     auto;
  margin-top: 50px;
  max-height: 100vh;
  padding:    1em;
}

#logoff_form img {
  display:    inline-block;
  max-height: 1.7em;
}
