:root {
    --grid-columns-qty: 12;
}
[role="dialog"] > form > * {
    display: grid;
    grid-template-columns: repeat( var(--grid-columns-qty) , 1fr);
    gap: .3em;
}

[role="dialog"] > form > *  * {
    grid-column-end: span var(--grid-columns-qty);
}
[role="dialog"] > form > *  *.w⅓ {
    grid-column-end: span calc( var(--grid-columns-qty) / 3 );
}
[role="dialog"] > form > *  *.w⅔ {
    grid-column-end: span calc( var(--grid-columns-qty) * 2 / 3 );
}
[role="dialog"] > form > *  *.w½{
    grid-column-end: span calc( var(--grid-columns-qty) / 2 );
}
[role="dialog"] > form > *  *.w¼ {
    grid-column-end: span calc( var(--grid-columns-qty) / 4 );
}
[role="dialog"] > form > *  *.w¾ {
    grid-column-end: span calc( var(--grid-columns-qty) * 3 / 4 );
}
