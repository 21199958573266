
.versionInfo{
    width: 100%;
    height: 1.3em;
    position: fixed;
    bottom: 0;
    display: inline-block;
    font-size: .7em;
    background: #fffa;
}
.versionInfo > * {
    padding-bottom: .3em;
    padding-left: 1em;
    display: inline;
}
.versionInfo > .Application-group,
.versionInfo > .MainClass {
    display: none;
}
