.period-selector > * { display: flex }

.main-content{
    display: flex;
    flex-direction: row;
}

.upline {
    justify-content: space-between;
    margin: -.7em 0 -1em;
}
.upline > * {
    max-width: 33%;
}

.main-content > .keywords-list {
    flex-shrink: 1;
    flex-basis: 17%;
}
.main-content > .reports-list {
    flex-shrink: 1;
    flex-basis: 13%;
}
.main-content > .pps-list {
    flex-shrink: 0;
    width: 77vw;
}

.pps-keywords > * {
    margin: .1em .3em;
}

.pps-keywords > .active {
    background: #383e47 !important;
    color: #fff !important;
}

.pps-keywords > * * {
    white-space: break-spaces !important;
}

.pps-description{
    width: 40%;
}
.pps-code{
    width: 20%;
}
.pps-title{
    width: 20%;
}
.pps-agent{
    width: 10%;
}
.pps-datetime{
    width: 5%;
}
.pps-code > [role="button"]{
    transition: opacity .7s;
    opacity: 0;
}

tr:hover .pps-code > [role="button"]{
    opacity: 1;
}

.keywords-pos,
.pps-list > table > thead {
    position: sticky;
    top: 50px;
    background: white;
    z-index: 9;
}
