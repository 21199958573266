
/* сортировка: */
thead tr th .resort {
    display: inline-block;
    float: right;
}
thead tr th .resort > * {
    transition: opacity .7s;
    opacity: 0;
}
thead tr th:hover .resort > * {
    opacity: 1;
}
thead tr th .resort > .bp5-active {
    opacity: 1;
}